<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="消息应用名称" prop="appName">
        <a-input v-model="form.appName" placeholder="请输入消息应用名称" />
      </a-form-model-item>
      <a-form-model-item label="消息应用编码" prop="appCode">
        <a-select placeholder="请选择" v-model="form.appCode">
          <a-select-option v-for="(d, key) in appCodesOptions" :key="key" :value="key">
            {{ key }}({{ appCodesOptions[key] }})</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="消息发送渠道类型 " prop="channelType">
        <a-select placeholder="请选择" v-model="form.channelType" @change="onChangeChannelType">
          <a-select-option v-for="(d, index) in msgChannelTypeOptions" :key="index" :value="parseInt(d.dictValue)">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="消息渠道配置" prop="config">
        <vue-json-editor
          v-model="jsonContent"
          :showBtns="false"
          mode="code"
          lang="zh"
          :expanded-on-start="true"
          @json-change="onJsonChange"
          @json-save="onJsonSave"
          @has-error="onError"
        />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.dictValue)">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
import { getMessageApp, addMessageApp, updateMessageApp, getAppConfigByChannelType } from '@/api/message/messageApp'

export default {
  name: 'CreateForm',
  props: {
    msgChannelTypeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    appCodesOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    vueJsonEditor
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        appName: undefined,
        appCode: undefined,
        channelType: undefined,
        config: '{}',
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      originChannelType: 0,
      hasJsonFlag: true, // json是否验证通过
      jsonContent: {},
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        appName: [
          { required: true, message: '消息应用名称不能为空', trigger: 'blur' }
        ],
        appCode: [
          { required: true, message: '消息应用编码不能为空', trigger: 'blur' }
        ],
        channelType: [
          { required: true, message: '消息发送渠道类型 不能为空', trigger: 'change' }
        ],
        config: [
          { required: true, message: '消息渠道配置不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onChangeChannelType(value) {
      if (value !== this.originChannelType) {
        getAppConfigByChannelType(value).then((response) => {
          this.jsonContent = JSON.parse(response.data)
        })
      } else {
        this.jsonContent = JSON.parse(this.form.config)
      }
    },
    onJsonChange(value) {
      // 实时保存
      this.onJsonSave(value)
    },
    onJsonSave(value) {
      this.jsonContent = value
      this.form.config = JSON.stringify(value)
      this.hasJsonFlag = true
    },
    onError(value) {
      console.log('json错误了value:', value)
      this.hasJsonFlag = false
    },
    // 检查json
    checkJson() {
      if (this.hasJsonFlag === false) {
        this.$message.error('请输入格式正确的JSON数据!')
        return false
      } else {
        return true
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        appName: undefined,
        appCode: undefined,
        channelType: undefined,
        config: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
      this.jsonContent = {}
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMessageApp(id).then(response => {
        this.form = response.data
        this.originChannelType = this.form.channelType
        this.jsonContent = JSON.parse(this.form.config)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid && this.hasJsonFlag) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMessageApp(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addMessageApp(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
/** 用::v-deep穿透样式修改所引用组件内的样式 */
::v-deep .jsoneditor-poweredBy {
  display: none !important;
}

::v-deep div.jsoneditor-menu {
  background-color: #023a08;
  border-bottom: 1px solid #013a07;
}
::v-deep div.jsoneditor {
  border: 1px solid #023a08;
}
</style>
