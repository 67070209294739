var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "消息应用名称", prop: "appName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息应用名称" },
                model: {
                  value: _vm.form.appName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appName", $$v)
                  },
                  expression: "form.appName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息应用编码", prop: "appCode" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.appCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "appCode", $$v)
                    },
                    expression: "form.appCode",
                  },
                },
                _vm._l(_vm.appCodesOptions, function (d, key) {
                  return _c(
                    "a-select-option",
                    { key: key, attrs: { value: key } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(key) +
                          "(" +
                          _vm._s(_vm.appCodesOptions[key]) +
                          ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息发送渠道类型 ", prop: "channelType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.onChangeChannelType },
                  model: {
                    value: _vm.form.channelType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "channelType", $$v)
                    },
                    expression: "form.channelType",
                  },
                },
                _vm._l(_vm.msgChannelTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: parseInt(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息渠道配置", prop: "config" } },
            [
              _c("vue-json-editor", {
                attrs: {
                  showBtns: false,
                  mode: "code",
                  lang: "zh",
                  "expanded-on-start": true,
                },
                on: {
                  "json-change": _vm.onJsonChange,
                  "json-save": _vm.onJsonSave,
                  "has-error": _vm.onError,
                },
                model: {
                  value: _vm.jsonContent,
                  callback: function ($$v) {
                    _vm.jsonContent = $$v
                  },
                  expression: "jsonContent",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: parseInt(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }